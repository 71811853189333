<template>
  <zingchart :data="myData" :series="items" v-if="processed"></zingchart>
</template>


<script>
import { mapActions } from "vuex";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
import { months } from "moment";

export default {
  name: "TattooerMonthlyGraph",
  components: {
    zingchart: zingchartVue,
  },
  computed: {
    currencyFormat() {
      let currency = this.$n("NaN", "currency");
      this.myData["scale-y"].format = currency.replace("NaN", "%scale-value");
      currency = this.$n("NaN", "currency");
      this.myData.plot.tooltip.text = currency.replace("NaN", "%v");
    },
  },
  data() {
    return {
      processed: false,
      items: [],
      myData: {
        type: "line",
        globals: {
          fontFamily: "Roboto Mono",
        },
        "background-color": "none",
        "scale-x": {
          labels: this.$t("months"),
          "max-items": 13,
          item: {
            angle: -70,
          },
        },
        "scale-y": {
          format: "",
          decimals: 2,
          guide: {
            "line-color": "var(--v-gris1-base)",
          },
        },
        plotarea: {
          adjustLayout: true,
        },
        plot: {
          decimals: 2,
          tooltip: {
            text: "",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("reports", ["getAdminMonthlyGraph"]),
    fetchTattooerMonthlyGraph() {
      this.getAdminMonthlyGraph({ options: this.options }).then((data) => {
        let items = [null];
        let info;
        let total = 0;
        for (let i = 1; i <= 12; i++) {
          info = data.find((x) => x.month == i);
          if (info) {
            total += info.price;
          }
          items.push(total);
        }
        this.items.push({
          values: items,
          "line-color": "var(--v-primary-base)",
          marker: {
            "background-color": "var(--v-primary-base)",
            "border-color": "transparent",
          },
        });
        this.processed = true;
      });
    },
  },
  mounted() {
    this.fetchTattooerMonthlyGraph();
    let currency = this.$n("NaN", "currency");
    this.myData["scale-y"].format = currency.replace("NaN", "%scale-value");
    currency = this.$n("NaN", "currency");
    this.myData.plot.tooltip.text = currency.replace("NaN", "%v");
  },
};
</script>